import React from 'react'

import Layout from 'components/Layout'
import QAndA from 'components/QAndA'

const ArticlePage = ({ pageContext }) => {
  const { article, categoriesToArticlesMap } = pageContext

  const createMarkup = (html) => {
    return {
      __html: html,
    }
  }

  return (
    <Layout seo={{ title: article.heading, description: article.excerpt }}>
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading'>
            <span className='underline'>{article.heading}</span>
          </h1>
          <div
            className='q-and-a-article'
            dangerouslySetInnerHTML={createMarkup(article.html)}
          />
        </section>
        <section className='section'>
          <p className='text text--small'>
            Vi friskriver oss från eventuella felskrivningar och feltolkningar.
          </p>
        </section>
        <section className='section'>
          <h1 className='heading'>Fler frågor och svar</h1>
          <QAndA categoriesToArticlesMap={categoriesToArticlesMap} />
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default ArticlePage
